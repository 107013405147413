import Vue from 'vue';

const mutations = {
  updateLocalStorage(state, { access, refresh }) {
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    state.accessToken = access;
    state.refreshToken = refresh;
  },
  updateAccess(state, access) {
    localStorage.setItem('access_token', access);
    state.accessToken = access;
  },
  destroyToken(state) {
    state.accessToken = null;
    state.refreshToken = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    // ['Vacations', 'SickList', 'Symptoms', 'Posts', 'Vaccines', 'Departments', 'CurrentStatus', 'Groups', 'ReportExistence'].forEach((ref) => {
    //   state.Data[ref].items = [];
    // });

    // ['department', 'employees', 'active_vacations', 'active_sick_list', 'last_report'].forEach((ref) => {
    //   state.Data.Department.items[ref] = [];
    // });
  },

  loadData(state, { key, data }) {
    Vue.set(state.Data[key], 'items', data);
  },

  // setSingleDataElement(state, { data }) {
  //   Vue.set(state.Data, 'CurrentSingleItem',  data);
  // },

  DeleteItem(state, { ref, id }) {
    state.Data[ref].items.splice(state.Data[ref].items.findIndex((item) => item.id === id), 1);
  },

  addAppError(state, error) {
    state.errors.push(error);
  },

  clearAppErrors(state) {
    state.errors = state.errors.splice();
  },

  setChartGenData(state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.Data.ChartGenData[key] = value;
    }
  },

  setLastCreated(state, data) {
    state.Data['Chart'].LastCreatedChart = data;
    state.Data['ChartGenData'].LastCreatedChart = data;
  },

  setChartDataElements(state, elements) {
    Vue.set(state.Data.ChartGenData, 'chartDataElements', elements)
  },

  setFilter(state, chapter) {
    state.Data.ChartsFilter = chapter
  }

};

export default mutations;
