import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import DataFile from '../views/DataFile.vue'
import Charts from '../views/Charts.vue'
import ChartEdit from '../views/ChartEdit.vue'
import ChartGen from '../views/ChartGen.vue'
import Pages from '../views/Pages.vue'
import PageEdit from '../views/PageEdit.vue'
import PageCreate from '../views/PageEdit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Вход',
      requiresLogged: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: 'Выход',
      requiresAuth: true,
    },
  },
  {
    path: '/datafiles',
    name: 'DataFile',
    component: DataFile,
    meta: {
      title: 'Файлы с данными',
      requiresAuth: true,
    },
  },
  {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    meta: {
      title: 'Графики',
      requiresAuth: true,
    },
  },
  {
    path: '/chart_gen',
    name: 'ChartGen',
    component: ChartGen,
    meta: {
      title: 'Создание графика',
      requiresAuth: true,
    },
  },
  // {
  //   path: '/chartdata_gen',
  //   name: 'ChartDataGen',
  //   component: ChartDataGen,
  //   meta: {
  //     title: 'Генерация данных графика',
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/charts/:id',
    name: 'ChartEdit',
    component: ChartEdit,
    meta: {
      title: 'Редактирование графика',
      requiresAuth: true,
    },
  },
  {
    path: '/pages',
    name: 'Pages',
    component: Pages,
    meta: {
      title: 'Страницы',
      requiresAuth: true,
    },
  },
  {
    path: '/pages/:id',
    name: 'PageEdit',
    component: PageEdit,
    meta: {
      title: 'Редактирование страницы',
      requiresAuth: true,
    },
  },
  {
    path: '/pages/create',
    name: 'PageCreate',
    component: PageCreate,
    meta: {
      title: 'Создание страницы',
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title || 'ГД';
  // next();
});

export default router
