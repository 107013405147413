<template>
  <div>
    <PageTitle title="chart gen" />
    <div class="columns">
      <div class="column is-one-quarter">
        <div class="box">
          <chart-gen-form />
        </div>
      </div>
      <div class="column">
        <ChartForm />
      </div>
    </div>
  </div>
</template>

<script>

import PageTitle from '../components/PageTitle.vue';
import ChartGenForm from '../components/ChartGenForm.vue';
import ChartForm from '../components/ChartFormSimple.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'ChartGenPage',
  components: {
    PageTitle,
    ChartGenForm,
    ChartForm,
  },
  data() {
    return {

    };
  },
  computed: {
    // DataFiles() {
    //   const data = this.$store.getters.Items('DataFile');
    //   return data;
    // },
    // SheetNames() {
    //   if (this.selectedDF.hasOwnProperty('sheets')) {
    //     return this.selectedDF.sheets.split(';')
    //   }
    //   return []
    // },
    // ActiveStep() {
    //   return this.ChartGenData.activeStep
    // },
    chartDataElements() {
      return this.ChartGenData.chartDataElements
    },
    ...mapGetters(['ChartGenData'])
  },
  created() {
    this.$store.dispatch('getData', {ref: 'DataFile'});
    this.$store.dispatch('getData', {ref: 'Chapter'});
    this.$store.dispatch('getData', {ref: 'ChartType'});
    this.$store.dispatch('getData', {ref: 'ChartNature'});
    this.$store.dispatch('getData', {ref: 'TooltipPointFormat'});
  },
  methods: {
    // get_chart(report) {
    //   getAPI.get(
    //     'api/get_chart/',
    //     {
    //       headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
    //       params: {
    //         data_file: this.selectedDF.id,
    //         sheet: this.selectedSheet,
    //         title: this.cellTitle,
    //         x: this.cellX,
    //       }
    //     },
    //   )
    //     .then((response) => {
    //       const a = response.data
    //       console.log(a)
    //       eventBus.$emit('chart-populate-edit-form', {
    //         name: a.title,
    //         chart_title: a.title,
    //         label_xaxis: a.x,
    //       });
    //     })
    //     .catch((err) => {
    //       this.message = err;
    //     });
    // },
  },
  // validations() {
  //   return {
  //     form: {
  //       selectedDF: {
  //         required,
  //       },
  //       selectedSheet: {
  //         required,
  //       },
  //       cellTitle: {
  //         required,
  //       },
  //       cellX: {
  //         required,
  //       },
  //       cellValues: {
  //         required,
  //       },
  //       titleFirstRow: {
  //         required,
  //       },
  //       valuesDirection: {
  //         required,
  //       },
  //     }
  //   }
  // }
};
</script>

<style>
</style>
