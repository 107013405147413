<template>
  <div>
    <PageTitle title="Графики" />
    <ChartList />
  </div>
</template>

<script>

import ChartList from '../components/ChartList.vue';
import PageTitle from '../components/PageTitle.vue';

export default {
  name: 'ChartPage',
  components: {
    ChartList,
    PageTitle,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
    this.$store.dispatch('getData', {ref: 'Chart'});
    this.$store.dispatch('getData', {ref: 'Chapter'});
    // this.$store.dispatch('getData', {ref: 'ChartType'});
    // this.$store.dispatch('getData', {ref: 'TooltipPointFormat'});
    // this.$store.dispatch('getData', {ref: 'ChartColor'});
  },
};
</script>

<style>
</style>
