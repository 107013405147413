<template>
  <div class="box">
    <form @submit.prevent="submit">
      <b-message
        v-if="$v.form.$error"
        type="is-danger"
        size="is-small"
      >
        Форма содержит ошибки
      </b-message>

      <b-field
        grouped
      >
        <b-field
          label="Название файла"
          :label-position="'on-border'"
          :type=" errors.file_name ? 'is-danger' : '' "
          :message="errors.file_name"
        >
          <b-input
            v-model="form.file_name"
            placeholder="Введите название..."
          />
        </b-field>

        <b-field
          class="file is-info"
          :class="{'has-name': !!form.file_file}"
          :type=" errors.file_file ? 'is-danger' : '' "
          :message="errors.file_file"
        >
          <b-upload
            v-model="form.file_file"
            class="file-label"
          >
            <span class="file-cta">
              <span class="file-label">Выберите файл</span>
            </span>
            <span
              v-if="form.file_file"
              class="file-name"
            >
              {{ form.file_file.name }}
            </span>
          </b-upload>
        </b-field>

        <b-field>
          <b-button
            native-type="submit"
            type="is-primary"
          >
            Добавить файл с данными
          </b-button>
        </b-field>
      </b-field>
    </form>

    <b-table
      :data="Data"
      :narrowed="true"
      :striped="true"
      default-sort="chapter"
    >
      <b-table-column
        v-slot="props"
        field="chapter"
        label="Глава"
        sortable
      >
        {{ props.row.chapter }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="file_name"
        label="Название файла"
        sortable
      >
        {{ props.row.file_name }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="file_file"
        label="Файл"
        sortable
      >
        <a
          :href="props.row.file_file"
          target="_blank"
        >ссылка</a>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="sheets"
        label="Список листов"
        sortable
      >
        {{ props.row.sheets }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>

import {
  required,
} from 'vuelidate/lib/validators';
import Vuelidate from 'vuelidate';
import Vue from 'vue';

Vue.use(Vuelidate);

export default {
  name: 'DataFileList',
  components: {
  },
  props: {
  },
  data() {
    return {
      errors: {},
      form: {
        file_name: null,
        file_file: null,
      },
      error_messages: {
        file_name: {
          invalid: 'Необходимо заполнить поле',
        },
        file_file: {
          invalid: 'Необходимо заполнить поле',
        },
      },
    };
  },
  computed: {
    Data() {
      const data = this.$store.getters.Items('DataFile');
      return data;
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        Object.keys(this.$v.form).forEach((key) => {
          if (!this.$v.form[key].$invalid) return;
          this.errors[key] = this.error_messages[key][(Object.keys(this.error_messages[key]).find((err) => (err !== 'invalid' && !this.$v.form[key][err])) || 'invalid')];
        });
        return;
      }

      this.$v.form.$reset();

      this.$store.dispatch('CreateItemWFile', {
        ref: 'DataFile',
        formdata: {file_name: this.form.file_name, file_file: this.form.file_file},
        postActions: [['getData', {ref: 'DataFile'}]],
      });

      this.resetForm();
    },

    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },

  },
  validations() {
    return {
      form: {
        file_name: {
          required,
        },
        file_file: {
          required,
        },
      }
    }
  }
};
</script>

<style>
span.it {
  font-style: italic;
}
</style>
