<template>
  <div>
    <PageTitle title="Файлы с данными" />
    <b-message type="is-danger">
      Внимание!<br> В ячейках из которых берутся значения не должно быть никаких ссылок, ВПР и арифметики. Только значения.
    </b-message>
    <List />
  </div>
</template>

<script>

import List from '../components/DataFileList.vue';
import PageTitle from '../components/PageTitle.vue';

export default {
  name: 'DataFilePage',
  components: {
    List,
    PageTitle,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
    this.$store.dispatch('getData', {ref: 'DataFile'});
  },
};
</script>

<style>
</style>
