import { axiosBase, getAPI } from '../api/axios-base';
// import { axiosBase } from '../api/axios-base';
import router from '../router/index';

const actions = {
  // run the below action to get a new access token on expiration
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      axiosBase.post('/api/token/refresh/', {
        refresh: context.state.refreshToken,
      }) // send the stored refresh token to the backend API
        .then((response) => { // if API sends back new access and refresh token update the store
          // console.log('New access successfully generated');
          context.commit('updateAccess', response.data.access);
          resolve(response.data.access);
        })
        .catch((err) => {
          // console.log('error in refreshToken Task');
          reject(err); // error generating new access and refresh token because
          // refresh token has expired
        });
    });
  },

  verifyToken(context) {
    // return new Promise((resolve, reject) => {
    return new Promise((resolve) => {
      axiosBase.post('/api/token/verify/', {
        token: context.state.accessToken,
      }) // send the stored refresh token to the backend API
        .then((response) => { // if API sends back new access and refresh token update the store
          // console.log('token verified');
          // context.commit('updateAccess', response.data.access);
          // resolve(response.data.access);
          resolve(response.data);
        })
        .catch((err) => {
          context.dispatch('setError', err);
          // console.log(error.config);
        });
    });
  },

  logoutUser(context) {
    if (context.getters.loggedIn) {
      return new Promise(() => {
        // console.log(resolve, reject);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        context.commit('destroyToken');
        router.push('/login');
      });
    }
    return null;
  },

  loginUser(context, credentials) {
    return new Promise((resolve, reject) => {
      // send the username and password to the backend API:
      axiosBase.post('/api/token/', {
        username: credentials.username,
        password: credentials.password,
      })
        // if successful update local storage:
        .then((response) => {
          // store the access and refresh token in localstorage
          context.commit('updateLocalStorage', { access: response.data.access, refresh: response.data.refresh });
          // if (context.state.Data.RefTmp.items.length == 0) {
          //   context.dispatch('getRefs')
          // }
          // context.dispatch('getRefs');

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getData(context, payload) {
    // console.log(payload.ref, payload.mode, payload.id)
    let link = context.state.Data[payload.ref].link
    if (payload.single) {
      link += payload.id
    }
    getAPI.get(
      link,
      {
        headers: { Authorization: `Bearer ${context.state.accessToken}` },
      },
    ) // proof that your access token is still valid; if not the
    // axios getAPI response interceptor will attempt to get a new  access token from the server.
      .then((response) => {
        if (payload.single) {
          context.commit('loadData', { key: payload.ref, data: [response.data] });
          // context.commit('setSingleDataElement', { data: response.data });
        } else {
          context.commit('loadData', { key: payload.ref, data: response.data });
        }
      })
      .catch((err) => {
        context.dispatch('setError', err); // refresh token expired or some other error status
      // console.log(err);
      });
  },

  CreateItem(context, payload) {
    console.log(payload)
    let fields = null;
    if (Array.isArray(payload.formdata)) {
      fields = payload.formdata;
    } else {
      fields = {};
      Object.keys(payload.formdata).forEach((key) => {
        fields[key] = payload.formdata[key];
      });
      delete fields.id;
    }
    console.log(fields)
    getAPI.post(
      context.state.Data[payload.ref].link,
      fields,
      {
        headers: { Authorization: `Bearer ${context.state.accessToken}` },
      },
    ) // proof that your access token is still valid; if not the
      // axios getAPI response interceptor will attempt to get a new  access token from the server.
      .then((response) => {
      // .then(() => {
        // console.log('Successfully created the item');
        // console.log(response);
        // context.commit('CreateItem', { ref: payload.ref, item: response.data });
        if (payload.ref === 'Chart') {
          context.commit('setLastCreated', response.data);
        }
        payload.postActions.forEach((action) => {
          context.dispatch(action[0], action[1]);
        });
      })
      .catch((err) => {
        context.dispatch('setError', err); // refresh token expired or some other error status
        // console.log(err);
      });
  },

  CreateItemWFile(context, payload) {
    // console.log(payload)
    let fields = null;
    if (Array.isArray(payload.formdata)) {
      fields = payload.formdata;
    } else {
      fields = {};
      Object.keys(payload.formdata).forEach((key) => {
        fields[key] = payload.formdata[key];
      });
      delete fields.id;
    }
    console.log(fields)
    getAPI.post(
      context.state.Data[payload.ref].link,
      fields,
      {
        headers: {
          Authorization: `Bearer ${context.state.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      },
    ) // proof that your access token is still valid; if not the
      // axios getAPI response interceptor will attempt to get a new  access token from the server.
      // .then((response) => {
      .then(() => {
        payload.postActions.forEach((action) => {
          context.dispatch(action[0], action[1]);
        });
      })
      .catch((err) => {
        context.dispatch('setError', err); // refresh token expired or some other error status
        // console.log(err);
      });
  },

  DeleteItem(context, payload) {
    // console.log(payload)
    getAPI.delete(
      `${context.state.Data[payload.ref].link}${payload.id}`,
      {
        headers: { Authorization: `Bearer ${context.state.accessToken}` },
      },
    ) // proof that your access token is still valid; if not the
      // axios getAPI response interceptor will attempt to get a new  access token from the server.
      // .then((response) => {
      .then(() => {
        // console.log('Successfully deleted the items');
        // console.log(response);
        context.commit('DeleteItem', payload);
      })
      .catch((err) => {
        context.dispatch('setError', err); // refresh token expired or some other error status
        // console.log(err);
      });
  },

  UpdateItem(context, payload) {
    // console.log(payload)
    const fields = {};
    Object.keys(payload.formdata).forEach((key) => {
      fields[key] = payload.formdata[key];
    });
    getAPI.patch(
      `${context.state.Data[payload.ref].link}${payload.formdata.id}/`,
      fields,
      {
        headers: { Authorization: `Bearer ${context.state.accessToken}` },
      },
    ) // proof that your access token is still valid; if not the
      // axios getAPI response interceptor will attempt to get a new  access token from the server.
      // .then((response) => {
      .then(() => {
        // console.log('Successfully updated the items');
        // console.log(response);
        payload.postActions.forEach((action) => {
          context.dispatch(action[0], action[1]);
        });
      })
      .catch((err) => {
        context.dispatch('setError', err); // refresh token expired or some other error status
        // console.log(err);
      });
  },

  setError(context, err) {
    context.commit('addAppError', err);
  },

  setLastCreated(context) {
    context.commit('setLastCreated', {});
  },

  setChartGenData(context, payload) {
    context.commit('setChartGenData', payload);
  },

  setChartDataElements(context, elements) {
    context.commit('setChartDataElements', elements);
  },

  setFilter(context, chapter) {
    context.commit('setFilter', chapter);
  },

  // emptySingleDataElement(context) {
  //   context.commit('setSingleDataElement', { data: {} });
  // }

};

export default actions;
