<template>
  <b-navbar
    fixed-top
    type="is-light"
    shadow
  >
    <template slot="brand">
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/' }"
      >
        <img
          src="../assets/logo.png"
          alt="---"
          width="28"
          height="28"
        >
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item
        tag="router-link"
        :to="{ name:'Home' }"
        exact
        :active-class="'is-active'"
      >
        Главная
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name:'DataFile' }"
        exact
        :active-class="'is-active'"
      >
        Файлы
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name:'Charts' }"
        exact
        :active-class="'is-active'"
      >
        Графики
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name:'ChartGen' }"
        exact
        :active-class="'is-active'"
      >
        Создание графика
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ name:'Pages' }"
        exact
        :active-class="'is-active'"
      >
        Страницы
      </b-navbar-item>

      <!-- <b-navbar-item
        tag="router-link"
        :to="{ name:'PageCreate' }"
        exact
        :active-class="'is-active'"
      >
        Страницы create
      </b-navbar-item> -->
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          <router-link
            v-if="accessToken==null"
            :to="{ name:'Login' }"
            class="button is-light"
          >
            Войти
          </router-link>
          <router-link
            v-if="accessToken!=null"
            :to="{ name:'Logout' }"
            class="button is-light"
          >
            Выйти
          </router-link>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavBar',
  computed: {
    ...mapState(['accessToken']),
  },
};
</script>

<style scoped>
</style>
