<template>
  <div>
    <PageTitle title="kjkj" />
    <!-- <ERH /> -->
  </div>
</template>

<script>

import PageTitle from '../components/PageTitle.vue';
// import ERH from '../components/ExtendedRenderHtml.vue';

export default {
  name: 'HomePage',
  components: {
    PageTitle,
    // ERH,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
};
</script>

<style>
</style>
