<template>
  <div
    v-show="form.id || form.chart"
    class="box"
  >
    <form @submit.prevent="submit">
      <b-message
        v-if="$v.form.$error"
        type="is-danger"
        size="is-small"
      >
        Форма содержит ошибки
      </b-message>
      <input
        v-model="form.id"
        type="hidden"
        name="id"
      >
      <input
        v-model="form.chart"
        type="hidden"
        name="chart"
      >

      <b-field
        label="Тип графика"
        :label-position="'on-border'"
        :type=" errors.chart_type ? 'is-danger' : '' "
        :message="errors.chart_type"
      >
        <b-select
          v-model="form.chart_type"
          placeholder="Выберите тип"
        >
          <option
            v-for="option in ChartType"
            :key="option.id"
            :value="option"
          >
            {{ option.name_ru }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Название элемента"
        :label-position="'on-border'"
        :type=" errors.chart_name ? 'is-danger' : '' "
        :message="errors.chart_name"
      >
        <b-input
          v-model="form.chart_name"
          placeholder="Введите название..."
        />
      </b-field>

      <b-field
        label="Значения"
        :label-position="'on-border'"
        :type=" errors.chart_data ? 'is-danger' : '' "
        :message="errors.chart_data"
      >
        <b-input
          v-model="form.chart_data"
          placeholder="Список значений..."
        />
      </b-field>

      <b-field
        label="Цвет графика"
        :type=" errors.chart_color ? 'is-danger' : '' "
        :message="errors.chart_color"
      >
        <!-- :label-position="'on-border'" -->
        <!-- grouped -->
        <b-field
          v-for="color in ChartColor"
          :key="`color_${color.id}`"
        >
          <b-radio
            v-model="form.chart_color"
            :native-value="color"
            size="is-small"
          >
            <span
              class="legend_color"
              :style="`background-color: ${color.color};`"
            />
            <!-- {{ color.name }} -->
          </b-radio>
        </b-field>
      </b-field>

      <b-field
        label="Стек"
        :label-position="'on-border'"
        :type=" errors.chart_stack ? 'is-danger' : '' "
        :message="errors.chart_stack"
      >
        <b-input
          v-model="form.chart_stack"
          placeholder="Введите значение..."
        />
      </b-field>

      <b-field>
        <b-button
          native-type="submit"
          type="is-primary"
        >
          {{ form.id ? 'Сохранить' : 'Добавить данные на график' }}
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>

import {
  required,
} from 'vuelidate/lib/validators';
import Vuelidate from 'vuelidate';
import Vue from 'vue';
import { eventBus } from '../main';

Vue.use(Vuelidate);

export default {
  name: 'ChartDataForm',
  props: {
  },
  data() {
    return {
      errors: {},
      form: {
        id: null,
        chart: null,
        chart_type: null,
        chart_name: null,
        chart_data: null,
        chart_color: null,
        chart_stack: null,
      },
      error_messages: {
        chart_type: {
          invalid: 'Необходимо заполнить поле',
        },
        chart_name: {
          invalid: 'Необходимо заполнить поле',
        },
        chart_data: {
          invalid: 'Необходимо заполнить поле',
        },
        chart_color: {
          invalid: 'Необходимо заполнить поле',
        },
      },
    };
  },
  computed: {
    ChartColor() {
      const data = this.$store.getters.Items('ChartColor');
      return data;
    },
    ChartType() {
      const data = this.$store.getters.Items('ChartType');
      return data;
    },
  },
  watch: {
  },
  created() {
    eventBus.$on('chartdata-populate-edit-form', (data) => {
      // // console.log(data)
      this.populateForm(data);
    });
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        Object.keys(this.$v.form).forEach((key) => {
          if (!this.$v.form[key].$invalid) return;
          this.errors[key] = this.error_messages[key][(Object.keys(this.error_messages[key]).find((err) => (err !== 'invalid' && !this.$v.form[key][err])) || 'invalid')];
        });
        return;
      }

      this.$v.form.$reset();

      const fields = this.form;

      if (this.form.id) {
        this.$store.dispatch('UpdateItem', {
          ref: 'ChartData', formdata: fields, postActions: [['getData', {ref: 'Chart'}]],
        });
      } else {
        this.$store.dispatch('CreateItem', {
          ref: 'ChartData', formdata: fields, postActions: [['getData', {ref: 'Chart'}]],
        });
      }
      this.resetForm();
    },
    populateForm(data) {
      this.form = data;
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },
  },
  validations() {
    return {
      form: {
        chart_type: {
          required,
        },
        chart_name: {
          required,
        },
        chart_data: {
          required,
        },
        chart_color: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
span.legend_color {
  border: 1px solid #bbb;
  display: inline-block;
  width: 20px;
  height: 10px;
}
</style>
