<template>
  <div>
    <b-collapse
      v-for="(element, index) of chartData"
      :key="index"
      :aria-id="'contentIdForA11y5-' + index"
      class="card"
      animation="slide"
      :open="isOpen == index"
      @open="isOpen = index"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          :aria-controls="'contentIdForA11y5-' + index"
          :aria-expanded="props.open"
        >
          <p class="card-header-title">
            ({{ element.chart_type.name_ru }}) {{ element.chart_name }}
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'"
            />
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <b-button
            size="is-small"
            type="is-success"
            :disabled="isFirst(element)"
            @click="moveUp($event, index)"
          >
            &uarr; Передвинуть вверх
          </b-button>
          &nbsp;
          <b-button
            size="is-small"
            type="is-success"
            :disabled="isLast(element)"
            @click="moveDown($event, index)"
          >
            Передвинуть вниз &darr;
          </b-button>
          <table class="table is-striped is-fullwidth is-bordered is-narrow">
            <tbody>
              <tr>
                <td>Тип</td>
                <td>{{ element.chart_type.name_ru }}</td>
              </tr>
              <tr>
                <td>Название</td>
                <td>{{ element.chart_name }}</td>
              </tr>
              <tr>
                <td>Данные</td>
                <td style="word-wrap: anywhere;">
                  {{ element.chart_data }}
                </td>
              </tr>
              <tr>
                <td>Цвет</td>
                <td>
                  <span
                    :style="`background-color: ${element.chart_color.color};`"
                    class="legend_color"
                  />
                </td>
              </tr>
              <tr>
                <td>Стек</td>
                <td>{{ element.chart_stack }}</td>
              </tr>
            </tbody>
          </table>
          <div class="has-text-right">
            <b-button
              type="is-warning"
              size="is-small"
              @click="EditItem(element)"
            >
              Редактировать данные
            </b-button>
            <b-button
              disabled
              type="is-danger"
              size="is-small"
              @click="DeleteItem(element.id)"
            >
              Удалить данные
            </b-button>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>

import { eventBus } from '../main';
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  name: 'ChartDataElement',
  components: {
  },
  props: {
    chart: {
      type: Object,
      default() {
        return {};
      },
    },
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },
    // index: {
    //   type: Number,
    //   default() {
    //     return 0;
    //   },
    // },
    itemOrderMin: {
      type: Number,
      default() {
        return 0;
      },
    },
    itemOrderMax: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      isOpen: 0,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {

    confirmDelete(id) {
      Dialog.confirm({
        message: 'Действительно удалить запись?',
        onConfirm: () => this.DeleteItem(id),
      });
    },

    DeleteItem(id) {
      console.log(id)
      this.$store.dispatch('DeleteItem', {
        ref: 'ChartData', id,
      });
    },

    EditItem(element) {
      // const a = this.$store.getters.GetRefItemById('ChartData', id);
      const a = element;
      // console.log(a)
      eventBus.$emit('chartdata-populate-edit-form', {
        id: a.id,
        chart: a.chart,
        chart_type: a.chart_type,
        chart_name: a.chart_name,
        chart_data: a.chart_data,
        chart_color: a.chart_color,
        chart_stack: a.chart_stack,
      });
    },

    isFirst (element) {
      return element.item_order === this.itemOrderMin
    },
    isLast (element) {
      return element.item_order === this.itemOrderMax
    },
    moveDown (event, ind) {
      this.swapItems(
        this.chart.chartdata_set[ind].id,
        this.chart.chartdata_set[ind + 1].id
      )
      this.isOpen += 1
    },
    moveUp (event, ind) {
      this.swapItems(
        this.chart.chartdata_set[ind].id,
        this.chart.chartdata_set[ind - 1].id
      )
      this.isOpen -= 1
    },
    swapItems (item1, item2) {
      this.$store.dispatch('CreateItem', {
        ref: 'SwapItems', formdata: {item1, item2}, postActions: [['getData', {ref: 'Chart'}]],
      });
    },
    // deleteItem (id) {
    //   this.$store.dispatch('DeleteItem', {
    //     ref: 'ChartData', formdata: {id}, postActions: [['getData', {ref: 'Chart'}]],
    //   });
    // },

  },
};
</script>

<style scoped>
span.legend_color {
  display: inline-block;
  width: 20px;
  height: 10px;
}
</style>
