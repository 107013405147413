import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const state = {
  accessToken: localStorage.getItem('access_token') || null,
  refreshToken: localStorage.getItem('refresh_token') || null,

  Data: {
    Chapter: {
      link: '/api/_chapter/',
      items: [],
    },
    ChartType: {
      link: '/api/_charttype/',
      items: [],
    },
    ChartNature: {
      link: '/api/_chartnature/',
      items: [],
    },
    TooltipPointFormat: {
      link: '/api/_tooltippointformat/',
      items: [],
    },
    ChartColor: {
      link: '/api/_chartcolor/',
      items: [],
    },
    DataFile: {
      link: '/api/_datafile/',
      items: [],
    },
    Chart: {
      link: '/api/_chart/',
      items: [],
      LastCreatedChart: {}
    },
    ChartData: {
      link: '/api/_chartdata/',
      items: [],
    },
    Page: {
      link: '/api/_page/',
      items: [],
    },
    ChartGenData: {
      selectedDF: {},
      selectedSheet: {},
      cellTitle: null,
      cellX: null,
      cellValues: null,
      titleFirstRow: false,
      valuesDirection: 'hor',
      chartDataElements: [],
      LastCreatedChart: {}
    },
    SwapItems: {
      link: '/api/_swap_items/',
    },
    CloneChart: {
      link: '/api/_chart_clone/',
    },
    CurrentSingleItem: {
    },
    ChartsFilter: {
      chapter: null
    }
  },
  errors: [],
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {},
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
