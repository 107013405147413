<template>
  <div>
    <ul>
      <li>
        Год: <i><b>{{ chart.year }}</b></i>.
        Глава: <i><b>{{ chart.chapter.name }}</b></i>.
        № рисунка: <i><b>{{ chart.number }}</b></i>.
        Тип графика: <i><b>{{ chart.chart_type.name_ru }}</b></i>
      </li>
      <li><b>{{ chart.name }}</b></li>
      <!-- <li>Номер рисунка: {{ chart.number }}</li>
      <li>Тип графика: {{ chart.chart_type.name }}</li>
      <li>Подпись: {{ chart.chart_title }}</li>
      <li>Подписи оси X: {{ chart.label_xaxis }}</li>
      <li>Подписи оси Y: {{ chart.label_yaxis }}</li>
      <li>С накоплением: {{ chart.stacking ? 'да' : 'нет' }}</li>
      <li>%: {{ chart.stacking_percent ? 'да' : 'нет' }}</li>
      <li>Подписи значений: {{ chart.tooltip ? 'да' : 'нет' }}</li>
      <li>Формат подписей: {{ chart.tooltip_format.format }}</li> -->
    </ul>
  </div>
</template>

<script>

export default {
  name: 'ChartDescription',
  components: {
  },
  props: {
    chart: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
</style>
