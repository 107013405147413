const getters = {
  loggedIn(state) {
    return state.accessToken != null;
  },

  // AppErrors: (state) => (ref) => {
  //   return state.errors;
  // },

  Items: (state) => (ref) => state.Data[ref].items,

  // DepartmentItems: (state) => (ref) => state.Data.Department.items[ref],

  // DepartmentOwnedItems: (state) => (ref) => state.Data.DepartmentOwned.items[ref],

  // CurrentAccount(state) {
  //   return state.Data.Department.items.account;
  // },

  // CommonData(state) {
  //   return state.Data.CommonData;
  // },

  // todayDate(state) {
  //   return [
  //     state.Data.CommonData.today.getDate(),
  //     state.Data.CommonData.today.getMonth() + 1,
  //     state.Data.CommonData.today.getFullYear(),
  //   ];
  // },

  // todayDateStr(state, gtrs) {
  //   const td = gtrs.todayDate;
  //   return `${td[2]}-${td[1].toString().padStart(2, '0')}-${td[0].toString().padStart(2, '0')}`;
  // },

  // isDir(state) {
  //   return state.Data.Groups.items.findIndex((el) => el.id === 2) > -1; // id=2 - дирекция
  // },

  // isThanos(state) {
  //   return state.Data.Groups.items.findIndex((el) => el.id === 3) > -1; // id=3 - Танос
  // },

  // canSendReport(state) {
  //   if (state.Data.ReportSendingAllowed.items.length > 0) {
  //     return state.Data.ReportSendingAllowed.items[0].allowed;
  //   }
  //   return false;
  // },

  GetRefItemById: (state) => (ref, id) => state.Data[ref].items.find((item) => item.id === id),

  // GetEmployeeFromDepartmentById: (state) => (id) => state.Data.Department.items.employees.find(
  //   (item) => item.id === id,
  // ),

  // RefItemsBare: (state) => (ref) => {
  //   return state.Data[ref].items.map((item) => { return {'id': item.id, 'name': item.name} });
  // },

  // LicenseMineralsAvailable: (state) => () => {
  //   return state.LicenseData['Minerals'].items.map((item) => { return item.mineral });
  // },

  // RefLabel: (state) => (ref) => {
  //   return state.RefMapping.Refs[ref].label;
  // },
  // RefNameMaxLength: (state) => (ref) => {
  //   return state.RefMapping.Refs[ref].name_max_length;
  // },

  // RefSubjectParents (state) {
  //   return state.Data.RefSubject.items.filter(el => el.parent_subject == null)
  // },
  // RefSubjectChildren (state) {
  //   return state.Data.RefSubject.items.filter(el => el.parent_subject != null)
  // },
  // RefSubjectChildrenSimple (state) {
  //   return state.Data.RefSubject.items
  //     .filter(el => el.parent_subject != null)
  //     .map(el => { return {'id': el.id, 'name': el.name} } )
  // },
  // RefWorkStageParents (state) {
  //   return state.Data.RefWorkStage.items.filter(el => el.parent_stage == null)
  // },
  // RefWorkStageChildren (state) {
  //   return state.Data.RefWorkStage.items.filter(el => el.parent_stage != null)
  // },

  // GetRefTmpItem: (state) => (id) => {
  //   // console.log(id);
  //   return state.Data.RefTmp.items.find(item => item.id === id)
  // },
  // // GetRefSelectedItem: (state) => (ref) => {
  // //   // console.log(ref);
  // //   return state.Data[ref].selectedItem
  // // },

  // GetRefItemIndex: (state) => (ref, id) => {
  //   return state.Data[ref].items.findIndex(item => { return item.id == id })
  // },

  // MainFormItems: (state) => (ref) => {
  //   return state.LicenseData[ref].items;
  // },
  // GetMainFormItemById: (state) => (ref, id) => {
  //   return state.LicenseData[ref].items.find(item => item.id === id)
  // },
  // CurrentLicense (state) {
  //   if (state.LicenseData['License'].items.hasOwnProperty('id')) {
  //     return state.LicenseData['License'].items.id;
  //   }
  //   return null
  // },

  // MineralGPTEnabled: (state, getters) => (id_mineral) => {
  //   let ref_data = getters.RefItems('RefMineralGPT').slice()
  //   ref_data = ref_data.filter(el => el.mineral == id_mineral)

  //   ref_data.sort( (a, b) => {
  //     if (a.gpt.name > b.gpt.name) {
  //       return 1;
  //     }
  //     if (a.gpt.name < b.gpt.name) {
  //       return -1;
  //     }
  //     return 0;
  //   } )
  //   return ref_data
  // },

  // Filters: (state) => {
  //   return state.Filters
  // }

  // CurrentSingleItem(state) {
  //   return state.Data.CurrentSingleItem
  //   // return state.Data
  // },

  ChartGenData: (state) => {
    return state.Data.ChartGenData
  },
  ChartsFilter: (state) => {
    return state.Data.ChartsFilter
  },

};

export default getters;
