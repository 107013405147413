import axios from 'axios';
import store from '../store/index';

// const APIUrl = 'http://localhost:8000';
const APIUrl = 'https://gd-back.data-geo.ru';

const axiosBase = axios.create({
  baseURL: APIUrl,
  // headers: { contentType: 'application/json' },
});

const getAPI = axios.create({
  baseURL: APIUrl,
});

getAPI.interceptors.response.use((response) => response, (err) => {
  // if error response status is 401, it means the request was invalid due to expired access token
  if (err.config && err.response && err.response.status === 401) {
    store.dispatch('refreshToken') // attempt to obtain new access token by running 'refreshToken' action
      .then((access) => {
        // if successful re-send the request to get the data from server
        axios.request({
          baseURL: APIUrl,
          method: err.config.method,
          headers: { Authorization: `Bearer ${access}` }, // the new access token is attached to the authorization header
          url: err.config.url,
        }).catch((error) => Promise.reject(error));
      })
      .catch((error) => Promise.reject(error));
  } else if (err.config && err.response && err.response.status === 400) {
    store.dispatch('setError', err.response.data);
  }
});

export { axiosBase, getAPI };
