<template>
  <div class="log" />
</template>

<script>
export default {
  name: 'LogoutPage',
  created() {
    this.$store.dispatch('logoutUser')
      .then(() => {
        this.$router.push({ name: 'login' });
      });
  },
};
</script>

<style scoped>
</style>
