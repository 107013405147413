import Vue from 'vue'
import App from './App.vue'
import IdleVue from 'idle-vue';
import router from './router'
import store from './store'

import 'buefy/dist/buefy.css'
import {
  ConfigProgrammatic,
  Table,
  Input,
  Navbar,
  Field,
  Button,
  Select,
  Message,
  Icon,
  // Switch,
  // Tag,
  Checkbox,
  Tabs,
  Radio,
  Notification,
  Upload,
  Collapse,
} from 'buefy'

Vue.config.productionTip = false

export const eventsHub = new Vue();
export const eventBus = new Vue();

Vue.use(Table)
Vue.use(Navbar)
Vue.use(Input)
Vue.use(Field)
Vue.use(Button)
Vue.use(Select)
Vue.use(Message)
Vue.use(Icon)
// Vue.use(Switch)
// Vue.use(Tag)
Vue.use(Checkbox)
Vue.use(Tabs)
Vue.use(Radio)
Vue.use(Notification)
Vue.use(Upload)
Vue.use(Collapse)

ConfigProgrammatic.setOptions({

})


Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 10 * 60000, // 60000 = 1 minute
}); // sets up the idle time,i.e. time left to logout the user on no activity

router.beforeEach((to, from, next) => {
  // if any of the routes in ./router.js has a meta named 'requiresAuth: true'
  // then check if the user is logged in before routing to this path:
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresLogged)) {
    // else if any of the routes in ./router.js has a meta named 'requiresLogged: true'
    // then check if the user is logged in; if true continue to home page else continue routing to
    // the destination path this comes to play if the user is logged in and tries to access
    // the login/register page
    if (store.getters.loggedIn) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  onIdle() { // dispatch logoutUser if no activity detected
    this.$store.dispatch('logoutUser');
  },
  router,
  store,
  created() {
    // if (this.$store.accessToken) {
    // this.$store.dispatch('getRefs');
    // console.log('aa');
    // }
    // this.$store.dispatch('getMain')
  },
  render: (h) => h(App),
}).$mount('#app');
