<script>
import UnChart from '../components/UniversalChart.vue';
import HeatMap from '../components/HeatmapPreview.vue';
import Map from '../components/MapPreview.vue';

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    html: {
      type: String,
      default: ""
    },
    year: {
      type: Number,
      default: null
    },
    chapter: {
      type: Number,
      default: null
    }
  },
  computed: {
    dynamicComponent() {
      return {
        components: {
          UnChart,
          HeatMap,
          Map
        },
        template: `<div>${this.html}</div>`,
        props: [
          /* define props as needed */
          'title',
          'year',
          'chapter'
        ],
        computed: {
          Charts() {
            // console.log(this.zyear, this.zchapter)
            let data = this.$store.getters.Items('Chart');
            if (this.year) {
              data = data.filter((element) => element.year === this.year)
            }
            if (this.chapter) {
              data = data.filter((element) => element.chapter.id === this.chapter)
            }
            return data;
          }
        },
        methods: {
          getChart(number) {
            // console.log(number, this.Charts)
            if (this.Charts) {
              return this.Charts.find((element) => element.number === number)
            }
            return null
          },
        },
      };
    },
  },
  render(createElement, context) {
    return createElement("div", [
      createElement(this.dynamicComponent, {
        props: {
          /* pass prop values as needed */
          title: this.title,
          year: this.year,
          chapter: this.chapter
        }
      })
    ]);
  }
};
</script>


<style>
.tbl tr td {
    padding: 4px;
    vertical-align: middle;
}
.cell_w_fraction {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.fraction {
    display: inline-block;
    text-align: center;
}
.fraction span:first-child {
    border-bottom: 1px solid #004a4a;
    /* padding-bottom: 3px; */
    display: block;
}
</style>
