<template>
  <div>
    <PageTitle title="Редактирование графика" />
    <div class="columns">
      <div class="column is-half">
        <div
          v-if="CurrentChart"
          class="box"
        >
          <ChartDescription
            :chart="CurrentChart"
          />

          <UnChart
            v-if="CurrentChart.chart_type.name !== 'heatmap'"
            :chart="CurrentChart"
          />
          <HeatmapPreview
            v-else
            :chart="CurrentChart"
          />

          <div class="has-text-right">
            <b-button
              type="is-info"
              size="is-small"
              @click="EditItem(CurrentChart.id)"
            >
              Редактировать параметры
            </b-button>
            &nbsp;
            <b-button
              type="is-primary"
              size="is-small"
              @click="AddItemData(CurrentChart.id)"
            >
              Добавить данные
            </b-button>
          </div>
          <br>

          <chart-data-element-collapse
            :chart-data="CurrentChart.chartdata_set"
            :chart="CurrentChart"
            :item-order-min="itemOrderMin"
            :item-order-max="itemOrderMax"
          />
        </div>
      </div>
      <div
        class="column is-half"
      >
        <EditChartForm />
        <EditDataForm />
      </div>
    </div>
  </div>
</template>

<script>

import { eventBus } from '../main';
import PageTitle from '../components/PageTitle.vue';
import ChartDescription from '../components/ChartDescription.vue';
import UnChart from '../components/UniversalChart.vue';
import HeatmapPreview from '../components/HeatmapPreview.vue'
import ChartDataElementCollapse from '../components/ChartDataElementCollapse.vue'
import EditChartForm from '../components/ChartForm.vue';
import EditDataForm from '../components/ChartDataForm.vue';

export default {
  name: 'ChartEditPage',
  components: {
    PageTitle,
    ChartDescription,
    UnChart,
    HeatmapPreview,
    ChartDataElementCollapse,
    EditChartForm,
    EditDataForm,
  },
  data() {
    return {
    };
  },
  computed: {
    CurrentChart() {
      const data = this.$store.getters.GetRefItemById('Chart', parseInt(this.$route.params.id));
      return data;
    },
    itemOrderMin () {
      const z = this.CurrentChart.chartdata_set.reduce(function (prev, curr) {
        return prev.item_order < curr.item_order ? prev : curr
      })
      return z.item_order
    },
    itemOrderMax () {
      const z = this.CurrentChart.chartdata_set.reduce(function (prev, curr) {
        return prev.item_order > curr.item_order ? prev : curr
      })
      return z.item_order
    }
  },
  created() {
    this.$store.dispatch('getData', {ref: 'Chart', single: true, id: parseInt(this.$route.params.id)});
    this.$store.dispatch('getData', {ref: 'Chapter'});
    this.$store.dispatch('getData', {ref: 'ChartType'});
    this.$store.dispatch('getData', {ref: 'ChartNature'});
    this.$store.dispatch('getData', {ref: 'TooltipPointFormat'});
    this.$store.dispatch('getData', {ref: 'ChartColor'});
  },

  methods: {
    EditItem(id) {
      // const a = this.$store.getters.GetRefItemById('Chart', id);
      const a = this.CurrentChart
      // console.log(a)
      eventBus.$emit('chart-populate-edit-form', {
        id: a.id,
        year: a.year,
        chapter: a.chapter,
        name: a.name,
        number: a.number,
        chart_type: a.chart_type,
        chart_nature: a.chart_nature,
        chart_title: a.chart_title,
        label_xaxis: a.label_xaxis,
        label_yaxis: a.label_yaxis,
        stacking: a.stacking,
        stacking_percent: a.stacking_percent,
        percent_in_labels: a.percent_in_labels,
        tooltip: a.tooltip,
        tooltip_for_many: a.tooltip_for_many,
        markers_enabled: a.markers_enabled,
        tooltip_format: a.tooltip_format,
        footnote: a.footnote,
        source: a.source,
        width: a.width,
        digits: a.digits,
        ymin: a.ymin,
      });
    },

    AddItemData(id) {
      // const a = this.$store.getters.GetRefItemById('Chart', id);
      // console.log(a)
      eventBus.$emit('chartdata-populate-edit-form', {
        chart: id,
      });
    },
  },
};
</script>

<style>
</style>
