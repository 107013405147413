<template>
  <div>
    <PageTitle title="Редактирование страницы" />
    <PageForm
      :key="$route.path"
    />
    <!-- :page="cEl" -->
  </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue';
import PageForm from '../components/PageForm.vue';

export default {
  name: 'PageEditPage',
  components: {
    PageTitle,
    PageForm,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.$store.dispatch('getData', {ref: 'Chapter'});
    this.$store.dispatch('getData', {ref: 'Chart'});
  },

  methods: {
  },
};
</script>

<style>
</style>
