<template>
  <div :id="'chart_' + chart.chapter.name + '_' + chart.number">
    <table class="heatmap">
      <thead>
        <tr class="heatmap-header">
          <td />
          <td
            v-for="title in titles"
            :key="title"
          >
            {{ title }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowindex) in chartData"
          :key="'row_'+rowindex"
        >
          <td>{{ row.name }}</td>
          <td
            v-for="(cell, cellindex) in row.data"
            :key="cellindex"
            :bgcolor="colors[cell]"
          />
        </tr>
      </tbody>
    </table>
    <div class="heatmap-legend">
      <div
        v-for="(lelement, lindex) in legend"
        :key="'legend_'+lindex"
        class="legend-item"
      >
        <span
          class="legend-color"
          :style="'background: '+colors[lindex]"
        >
          &nbsp;
        </span>
        {{ lelement }}
      </div>
    </div>
    <p class="footnote">
      {{ chart.footnote }}
    </p>
    <p class="table-header">
      <span>Рис. {{ chart.number }}</span> {{ chart.chart_title }}
      <i v-if="chart.source"> (Источник: {{ chart.source }})</i>
    </p>
  </div>
</template>

<script>

export default {
  name: 'HeatmapPreview',
  components: {
  },
  props: {
    chart: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      colors: [
        '#dadada',
        '#878787',
        '#005178',
        '#f08262',
        '#b3b2b2',
        '#ba403c',
        '#8bafc5',
        '#d4e4ef'
      ]
    };
  },
  computed: {
    titles() {
      return this.chart.label_xaxis.split(',')
    },
    legend() {
      return this.chart.label_yaxis.split(',')
    },
    chartData () {
      let data = []
      if (this.chart.chartdata_set) {
        this.chart.chartdata_set.forEach((element) => {
          data.push(
            {
              name: element.chart_name,
              data: element.chart_data.split(',').map(Number),
            }
          )
        })
      }
      return data
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
  table.heatmap {
    width: 100%;
  }
  tr.heatmap-header td {
    writing-mode: sideways-lr;
    min-width: 20px;
    padding-bottom: 5px;
    font-size: 0.8em;
    border-bottom: 2px solid #005178;
  }
  table.heatmap tbody td:not(:first-child) {
    padding: 5px;
  }
  table.heatmap tbody td:not(:first-child) {
    border: 1px dotted #b3b2b2;
  }
  table.heatmap tr:last-child td {
    border-bottom: 2px solid #005178;
  }
  div.heatmap-legend {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  span.legend-color {
    padding: 0 5px;
    margin-right: 10px;
  }
</style>
