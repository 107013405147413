<template>
  <div>
    <PageTitle title="Страницы" />
    <List />
  </div>
</template>

<script>

import List from '../components/PageList.vue';
import PageTitle from '../components/PageTitle.vue';

export default {
  name: 'PagePage',
  components: {
    List,
    PageTitle,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
    this.$store.dispatch('getData', {ref: 'Page'});
    this.$store.dispatch('getData', {ref: 'Chapter'});
  },
};
</script>

<style>
</style>
