<template>
  <ul>
    <li>Глава: <i><b>{{ page.chapter.name }}</b></i></li>
    <li><b>{{ page.title }}</b></li>
  </ul>
</template>

<script>

export default {
  name: 'PageDescription',
  components: {
  },
  props: {
    page: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
</style>
