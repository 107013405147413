<template>
  <form @submit.prevent="submit">
    <b-message
      v-if="$v.form.$error"
      type="is-danger"
      size="is-small"
    >
      Форма содержит ошибки
    </b-message>

    <b-field
      label="Файл с данными"
      :label-position="'on-border'"
    >
      <b-select
        v-model="form.selectedDF"
        :type=" errors.selectedDF ? 'is-danger' : '' "
        :message="errors.selectedDF"
        placeholder="Выберите файл с данными"
      >
        <option
          v-for="option in DataFiles"
          :key="option.id"
          :value="option"
        >
          {{ option.file_name }}
        </option>
      </b-select>
    </b-field>

    <b-field
      :type=" errors.selectedSheet ? 'is-danger' : '' "
      :message="errors.selectedSheet"
      label="Лист с данными"
      :label-position="'on-border'"
    >
      <b-select
        v-model="form.selectedSheet"
        placeholder="Выберите лист с данными"
      >
        <option
          v-for="option in SheetNames"
          :key="option.id"
          :value="option"
        >
          {{ option }}
        </option>
      </b-select>
    </b-field>

    <b-field
      :type=" errors.cellTitle ? 'is-danger' : '' "
      :message="errors.cellTitle"
      label="Координаты ячейки с названием"
      :label-position="'on-border'"
    >
      <b-input
        v-model="form.cellTitle"
        placeholder="Например A2..."
      />
    </b-field>

    <b-field
      :type=" errors.cellX ? 'is-danger' : '' "
      :message="errors.cellX"
      label="Диапазон ячеек с подписями X"
      :label-position="'on-border'"
    >
      <b-input
        v-model="form.cellX"
        placeholder="Например B4:K4..."
      />
    </b-field>

    <b-field
      :type=" errors.cellValues ? 'is-danger' : '' "
      :message="errors.cellValues"
      label="Диапазон ячеек со значениями"
      :label-position="'on-border'"
    >
      <b-input
        v-model="form.cellValues"
        placeholder="Например B4:K4..."
      />
    </b-field>

    <b-field
      :type=" errors.valuesDirection ? 'is-danger' : '' "
      :message="errors.valuesDirection"
      label="'Направление' значений"
      :label-position="'on-border'"
    >
      <b-select
        v-model="form.valuesDirection"
        placeholder="Выберите направление"
      >
        <option value="hor">
          Горизонтально
        </option>
        <option value="vert">
          Вертикально
        </option>
      </b-select>
    </b-field>

    <b-field
      :type=" errors.titleFirstRow ? 'is-danger' : '' "
      :message="errors.titleFirstRow"
    >
      <b-checkbox v-model="form.titleFirstRow">
        Название в 1й ячейке
      </b-checkbox>
    </b-field>

    <b-field>
      <b-checkbox v-model="form.isHeatmap">
        heatmap
      </b-checkbox>
    </b-field>

    <b-field>
      <b-button
        type="is-primary"
        native-type="submit"
      >
        Получить параметры графика
      </b-button>
    </b-field>
  </form>
</template>

<script>

import { eventBus } from '../main';
import { getAPI } from '../api/axios-base';
import {
  required,
} from 'vuelidate/lib/validators';
import Vuelidate from 'vuelidate';
import Vue from 'vue';

Vue.use(Vuelidate);

export default {
  name: 'ChartGenForm',
  components: {
  },
  data() {
    return {
      errors: {},
      form: {
        selectedDF: {},
        selectedSheet: {},
        cellTitle: null,
        cellX: null,
        cellValues: null,
        titleFirstRow: false,
        valuesDirection: 'hor',
        isHeatmap: false,
      },
      error_messages: {
        selectedDF: {
          invalid: 'Необходимо заполнить поле',
        },
        selectedSheet: {
          invalid: 'Необходимо заполнить поле',
        },
        cellTitle: {
          invalid: 'Необходимо заполнить поле',
        },
        cellX: {
          invalid: 'Необходимо заполнить поле',
        },
        cellValues: {
          invalid: 'Необходимо заполнить поле',
        },
        // titleFirstRow: {
        //   invalid: 'Необходимо заполнить поле',
        // },
        valuesDirection: {
          invalid: 'Необходимо заполнить поле',
        },
      }
    };
  },
  computed: {
    DataFiles() {
      const data = this.$store.getters.Items('DataFile');
      return data;
    },
    SheetNames() {
      if (this.form.selectedDF.hasOwnProperty('sheets')) {
        return this.form.selectedDF.sheets.split(';')
      }
      return []
    },
  },
  created() {
    this.$store.dispatch('getData', {ref: 'DataFile'});
    this.$store.dispatch('getData', {ref: 'Chapter'});
  },
  methods: {
    get_chart() {
      getAPI.get(
        // 'api/get_chart/',
        'api/get_chart_full/',
        {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          params: {
            data_file: this.form.selectedDF.id,
            sheet: this.form.selectedSheet,
            title: this.form.cellTitle,
            x: this.form.cellX,
            values: this.form.cellValues,
            direction: this.form.valuesDirection,
            title_first_row: this.form.titleFirstRow,
            is_heatmap: this.form.isHeatmap,
          }
        },
      )
        .then((response) => {
          const a = response.data
          eventBus.$emit('chart-populate-edit-form', {
            name: a.title,
            chart_title: a.title,
            label_xaxis: a.x,
          });
          this.$store.dispatch('setChartDataElements', a.values)

          let valuesStr = ''
          a.values.forEach((val) => {
            valuesStr += `${val.chart_name} --- ${val.chart_data}<br>`
          })
          this.$buefy.notification.open({
            duration: 5000,
            message: `Из файла прочитаны следующие данные:<br>Название: ${a.title}<br>Подписи: ${a.x}<br>Значения:<br> ${valuesStr}`,
            type: 'is-warning',
          })
            // indefinite: true,
        })
        .catch((err) => {
          this.message = err;
        });
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        Object.keys(this.$v.form).forEach((key) => {
          if (!this.$v.form[key].$invalid) return;
          this.errors[key] = this.error_messages[key][(Object.keys(this.error_messages[key]).find((err) => (err !== 'invalid' && !this.$v.form[key][err])) || 'invalid')];
        });
        return;
      }

      this.$v.form.$reset();

      const fields = this.form;
      this.$store.dispatch('setLastCreated')
      this.$store.dispatch('setChartGenData', fields)

      this.get_chart();

      // this.resetForm();
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },
  },
  validations() {
    return {
      form: {
        selectedDF: {
          required,
        },
        selectedSheet: {
          required,
        },
        cellTitle: {
          required,
        },
        cellX: {
          required,
        },
        cellValues: {
          required,
        },
        // titleFirstRow: {
        //   required,
        // },
        valuesDirection: {
          required,
        },
      }
    }
  }
};
</script>

<style>
</style>
