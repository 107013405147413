<template>
  <div
    :id="'chart_' + chart.chapter.name + '_' + chart.number"
    :style="chart.width ? `max-width: ${chart.width}px; margin: 0 auto;` : ''"
  >
    <highcharts
      :options="chartOptions"
    />
    <p class="footnote">
      {{ chart.footnote }}
    </p>
    <p class="table-header">
      <span>Рис. {{ chart.number }}</span> {{ chart.chart_title }}
      <i v-if="chart.source"><br>Источник: {{ chart.source }}</i>
    </p>
  </div>
</template>

<script>

import {Chart} from 'highcharts-vue'

export default {
  name: 'UnChart',
  components: {
    highcharts: Chart
  },
  props: {
    chart: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    chartData () {
      let data = []
      if (this.chart.chartdata_set) {
        this.chart.chartdata_set.forEach((element) => {
          data.push(
            {
              type: element.chart_type.name,
              name: element.chart_name,
              stack: element.chart_stack,
              data: element.chart_data.split(',').map(Number),
              color: element.chart_color.color
            }
          )
        })
      }
      return data
    },
    plotOptions () {
      const a = {}
      const prcnt = this.chart.stacking_percent
      const prLabels = this.chart.percent_in_labels
      const digits = this.chart.digits
      if (this.chartData.length) {
        this.chartData.forEach((element) => {
          a[element.type] = {
            stacking: this.chart.stacking ? (prcnt ? 'percent' : 'normal') : '',
            dataLabels: {
              formatter () {
                if (prcnt) {
                  if (prLabels) {
                    return `${+this.y.toFixed(digits)} (${Math.round(this.percentage)}%)`
                  }
                }
                return +this.y.toFixed(digits)
              },
              enabled: this.chart.tooltip,
            },
            marker: {
              enabled: this.chart.markers_enabled
            }
          }
        })
      }
      return a
    },

    chartOptions () {
      return {
        caption: {
          text: ''
        },
        chart: {
          // width: this.chart.width ? this.chart.width : null,
          // width: '40%',
          backgroundColor: '#ffffff',
          className: 'chart1',
          type: this.chart.chart_type.name
        },
        plotOptions: this.plotOptions,
        yAxis: [{
          title: {
            text: this.chart.label_yaxis
          },
          labels: {
            formatter: function () {
              return this.value
            }
          },
          min: this.chart.ymin ? 0 : null
        }],
        xAxis: {
          categories: this.chart.label_xaxis.split(',')
        },
        title: {
          text: ''
        },
        subtitle: {
        },
        tooltip: {
          pointFormat: this.chart.tooltip_format.format,
          shared: this.chart.tooltip_for_many
        },
        legend: {
        },
        series: this.chartData
      }
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
// highcharts.js line 47
</script>

<style>
  .table-header {
    font-size: 1.1em;
    margin: 0.5em 0;
    background: #eee;
    padding: 0.5em;
  }
  .table-header span {
    color: inherit;
    font-weight: bold;
  }
</style>
