<template>
  <div
    v-show="form.id"
    class="box"
  >
    <form @submit.prevent="submit">
      <b-message
        v-if="$v.form.$error"
        type="is-danger"
        size="is-small"
      >
        Форма содержит ошибки
      </b-message>
      <input
        v-model="form.id"
        type="hidden"
        name="id"
      >

      <b-field
        grouped
      >
        <b-field
          label="Год"
          :label-position="'on-border'"
          :type=" errors.year ? 'is-danger' : '' "
          :message="errors.year"
          expanded
        >
          <b-input
            v-model="form.year"
            placeholder="Введите год..."
            :maxlength="4"
          />
        </b-field>

        <b-field
          label="Глава"
          :label-position="'on-border'"
          :type=" errors.chapter ? 'is-danger' : '' "
          :message="errors.chapter"
        >
          <b-select
            v-model="form.chapter"
            placeholder="Выберите главу"
          >
            <option
              v-for="option in Chapter"
              :key="option.id"
              :value="option"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Природа графика"
          :label-position="'on-border'"
          :type=" errors.chart_nature ? 'is-danger' : '' "
          :message="errors.chart_nature"
        >
          <b-select
            v-model="form.chart_nature"
            placeholder="Выберите значение"
          >
            <option
              v-for="option in ChartNature"
              :key="option.id"
              :value="option"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field
        grouped
      >
        <b-field
          label="Тип графика"
          :label-position="'on-border'"
          :type=" errors.chart_type ? 'is-danger' : '' "
          :message="errors.chart_type"
        >
          <b-select
            v-model="form.chart_type"
            placeholder="Выберите тип"
          >
            <option
              v-for="option in ChartType"
              :key="option.id"
              :value="option"
            >
              {{ option.name_ru }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Номер рисунка"
          :label-position="'on-border'"
          :type=" errors.number ? 'is-danger' : '' "
          :message="errors.number"
        >
          <b-input
            v-model="form.number"
            placeholder="Введите номер..."
            :maxlength="4"
          />
        </b-field>
      </b-field>

      <b-field
        label="Название"
        :label-position="'on-border'"
        :type=" errors.name ? 'is-danger' : '' "
        :message="errors.name"
      >
        <b-input
          v-model="form.name"
          placeholder="Введите название..."
        />
      </b-field>

      <b-field
        label="Подпись"
        :label-position="'on-border'"
        :type=" errors.chart_title ? 'is-danger' : '' "
        :message="errors.chart_title"
      >
        <b-input
          v-model="form.chart_title"
          placeholder="Введите подпись..."
        />
      </b-field>

      <b-field
        label="Подписи оси X"
        :label-position="'on-border'"
        :type=" errors.label_xaxis ? 'is-danger' : '' "
        :message="errors.label_xaxis"
      >
        <b-input
          v-model="form.label_xaxis"
          placeholder="Список значений..."
        />
      </b-field>

      <b-field
        label="Подписи оси Y"
        :label-position="'on-border'"
        :type=" errors.label_yaxis ? 'is-danger' : '' "
        :message="errors.label_yaxis"
      >
        <b-input
          v-model="form.label_yaxis"
          placeholder="Как правило - единицы измерений..."
        />
      </b-field>

      <b-field
        grouped
      >
        <b-field
          :type=" errors.stacking ? 'is-danger' : '' "
          :message="errors.stacking"
        >
          <b-checkbox v-model="form.stacking">
            С накоплением
          </b-checkbox>
        </b-field>

        <b-field
          :type=" errors.stacking_percent ? 'is-danger' : '' "
          :message="errors.stacking_percent"
        >
          <b-checkbox v-model="form.stacking_percent">
            100%
          </b-checkbox>
        </b-field>
      </b-field>

      <b-field
        grouped
      >
        <b-field
          :type=" errors.percent_in_labels ? 'is-danger' : '' "
          :message="errors.percent_in_labels"
        >
          <b-checkbox v-model="form.percent_in_labels">
            % в подписях
          </b-checkbox>
        </b-field>

        <b-field
          :type=" errors.tooltip ? 'is-danger' : '' "
          :message="errors.tooltip"
        >
          <b-checkbox v-model="form.tooltip">
            Подписи значений
          </b-checkbox>
        </b-field>

        <b-field
          :type=" errors.tooltip_for_many ? 'is-danger' : '' "
          :message="errors.tooltip_for_many"
        >
          <b-checkbox v-model="form.tooltip_for_many">
            Объединить подписи
          </b-checkbox>
        </b-field>
      </b-field>

      <b-field
        :type=" errors.markers_enabled ? 'is-danger' : '' "
        :message="errors.markers_enabled"
      >
        <b-checkbox v-model="form.markers_enabled">
          Рисовать точки значений
        </b-checkbox>
        <b-checkbox v-model="form.ymin">
          Y начинается с 0
        </b-checkbox>
      </b-field>

      <b-field
        :label-position="'on-border'"
        label="Формат подписей"
        :type=" errors.tooltip_format ? 'is-danger' : '' "
        :message="errors.tooltip_format"
      >
        <b-select
          v-model="form.tooltip_format"
          placeholder="Выберите формат"
        >
          <option
            v-for="option in TooltipPointFormat"
            :key="option.id"
            :value="option"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Сноски"
        :label-position="'on-border'"
      >
        <b-input
          v-model="form.footnote"
          type="textarea"
          placeholder="Введите текст..."
          rows="5"
        />
      </b-field>

      <b-field
        label="Источник"
        :label-position="'on-border'"
        :type=" errors.source ? 'is-danger' : '' "
        :message="errors.source"
      >
        <b-input
          v-model="form.source"
          placeholder="Введите текст..."
        />
      </b-field>

      <b-field
        label="Ширина"
        :label-position="'on-border'"
      >
        <b-input
          v-model="form.width"
          placeholder="Введите значение..."
        />
      </b-field>

      <b-field
        label="Разрядность значений для подписей"
        :label-position="'on-border'"
      >
        <b-input
          v-model="form.digits"
          placeholder="Введите значение..."
        />
      </b-field>

      <b-field>
        <b-button
          native-type="submit"
          type="is-primary"
        >
          {{ form.id ? 'Сохранить' : 'Добавить график' }}
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>

import {
  required,
//   helpers,
//   email,
} from 'vuelidate/lib/validators';
import Vuelidate from 'vuelidate';
import Vue from 'vue';
import { eventBus } from '../main';

Vue.use(Vuelidate);

// const properFIO = helpers.regex('properFIO', /[а-яА-Я-] [А-Я]\.[А-Я]\.$/);

export default {
  name: 'ChartForm',
  props: {
  },
  data() {
    return {
      errors: {},
      form: {
        id: null,
        year: null,
        chapter: null,
        name: null,
        number: null,
        chart_type: null,
        chart_nature: null,
        chart_title: null,
        label_xaxis: null,
        label_yaxis: null,
        stacking: false,
        stacking_percent: false,
        percent_in_labels: false,
        tooltip: false,
        tooltip_for_many: false,
        markers_enabled: false,
        tooltip_format: null,
        footnote: null,
        source: null,
        width: null,
        ymin: false
      },
      error_messages: {
        year: {
          invalid: 'Необходимо заполнить поле',
        },
        chapter: {
          invalid: 'Необходимо заполнить поле',
        },
        name: {
          invalid: 'Необходимо заполнить поле',
        },
        number: {
          invalid: 'Необходимо заполнить поле',
        },
        chart_type: {
          invalid: 'Необходимо заполнить поле',
        },
        chart_title: {
          invalid: 'Необходимо заполнить поле',
        },
        label_xaxis: {
          invalid: 'Необходимо заполнить поле',
        },
        label_yaxis: {
          invalid: 'Необходимо заполнить поле',
        },
        tooltip_format: {
          invalid: 'Необходимо заполнить поле',
        },
        source: {
          invalid: 'Необходимо заполнить поле',
        },
      },
    };
  },
  computed: {
    Chapter() {
      const data = this.$store.getters.Items('Chapter');
      return data;
    },
    ChartType() {
      const data = this.$store.getters.Items('ChartType');
      return data;
    },
    ChartNature() {
      const data = this.$store.getters.Items('ChartNature');
      return data;
    },
    TooltipPointFormat() {
      const data = this.$store.getters.Items('TooltipPointFormat');
      return data;
    },
  },
  watch: {
  },
  created() {
    eventBus.$on('chart-populate-edit-form', (data) => {
      // // console.log(data)
      this.populateForm(data);
    });
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        Object.keys(this.$v.form).forEach((key) => {
          if (!this.$v.form[key].$invalid) return;
          this.errors[key] = this.error_messages[key][(Object.keys(this.error_messages[key]).find((err) => (err !== 'invalid' && !this.$v.form[key][err])) || 'invalid')];
        });
        return;
      }

      this.$v.form.$reset();

      const fields = this.form;

      if (this.form.id) {
        this.$store.dispatch('UpdateItem', {
          ref: 'Chart', formdata: fields, postActions: [['getData', {ref: 'Chart'}]],
        });
      } else {
        this.$store.dispatch('CreateItem', {
          ref: 'Chart', formdata: fields, postActions: [['getData', {ref: 'Chart'}]],
        });
      }
      this.resetForm();
    },
    populateForm(data) {
      this.form = data;
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        if (('stacking', 'stacking_percent', 'tooltip').includes(key)) {
          this.form[key] = false;
        } else {
          this.form[key] = null;
        }
      });
    },
  },
  validations() {
    return {
      form: {
        year: {
          required,
        },
        chapter: {
          required,
        },
        name: {
          required,
        },
        number: {
          required,
        },
        chart_type: {
          required,
        },
        chart_title: {
          required,
        },
        label_xaxis: {
          required,
        },
        label_yaxis: {
          required,
        },
        tooltip_format: {
          required,
        },
        source: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
</style>
