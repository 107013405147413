<template>
  <div id="app">
    <NavBar />
    <main class="main">
      <router-view />
    </main>
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.main {
  padding: 1rem;
  /* background-color: ghostwhite; */
  /* background-color: lavender; */
  /* background-color: whitesmoke; */
  background-color: #B1D0B0;
  min-height: calc(100vh - 20px);
  /* overflow: hidden; */
  position: relative;
  z-index: 6;
}
</style>
