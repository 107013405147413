<template>
  <div
    class="box"
  >
    <form @submit.prevent="submit">
      <b-message
        v-if="$v.form.$error"
        type="is-danger"
        size="is-small"
      >
        Форма содержит ошибки
      </b-message>
      <input
        v-model="form.id"
        type="hidden"
        name="id"
      >

      <b-field
        grouped
      >
        <b-field
          label="Год"
          :label-position="'on-border'"
          :type=" errors.year ? 'is-danger' : '' "
          :message="errors.year"
          expanded
        >
          <b-input
            v-model="form.year"
            placeholder="Введите год..."
            :maxlength="4"
          />
        </b-field>

        <b-field
          label="Глава"
          :label-position="'on-border'"
          :type=" errors.chapter ? 'is-danger' : '' "
          :message="errors.chapter"
        >
          <b-select
            v-model="form.chapter"
            placeholder="Выберите главу"
          >
            <option
              v-for="option in Chapter"
              :key="option.id"
              :value="option"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Порядковый номер"
          :label-position="'on-border'"
          :type=" errors.item_order ? 'is-danger' : '' "
          :message="errors.item_order"
          expanded
        >
          <b-input
            v-model="form.item_order"
            placeholder="Введите номер..."
            :maxlength="4"
          />
        </b-field>
      </b-field>

      <b-field
        label="Заголовок"
        :label-position="'on-border'"
        :type=" errors.title ? 'is-danger' : '' "
        :message="errors.title"
      >
        <b-input
          v-model="form.title"
          placeholder="Введите подпись..."
        />
      </b-field>

      <div class="columns">
        <div class="column">
          <b-field
            label="Исходный текст"
            :label-position="'on-border'"
            :type=" errors.text ? 'is-danger' : '' "
            :message="errors.text"
          >
            <b-input
              v-model="form.text"
              type="textarea"
              placeholder="Введите текст..."
              rows="10"
            />
          </b-field>

          <b-field>
            <b-button
              native-type="submit"
              type="is-primary"
            >
              {{ form.id ? 'Сохранить' : 'Добавить страницу' }}
            </b-button>
          </b-field>
        </div>
        <div class="column">
          <h5 class="subtitle is-5">
            Предпросмотр
          </h5>
          <PagePreview
            v-if="form.year && form.chapter"
            :title="form.title"
            :html="form.text"
            :year="form.year"
            :chapter="form.chapter.id"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import PagePreview from './PagePreview.vue';

import {
  required,
//   helpers,
//   email,
} from 'vuelidate/lib/validators';
import Vuelidate from 'vuelidate';
import Vue from 'vue';
import { getAPI } from '../api/axios-base';
// import UnChart from '../components/UnChart.vue';

Vue.use(Vuelidate);

export default {
  name: 'PageForm',
  components: {
    // UnChart,
    PagePreview
  },
  props: {
  },
  data() {
    return {
      errors: {},
      form: {
        id: null,
        year: null,
        chapter: null,
        item_order: null,
        title: null,
        text: null,
      },
      error_messages: {
        year: {
          invalid: 'Необходимо заполнить поле',
        },
        chapter: {
          invalid: 'Необходимо заполнить поле',
        },
        item_order: {
          invalid: 'Необходимо заполнить поле',
        },
        title: {
          invalid: 'Необходимо заполнить поле',
        },
        text: {
          invalid: 'Необходимо заполнить поле',
        },
      },
    };
  },
  computed: {
    Chapter() {
      const data = this.$store.getters.Items('Chapter');
      return data;
    },
    // Charts() {
    //   let data = this.$store.getters.Items('Chart');
    //   if (this.form.year) {
    //     data = data.filter((element) => element.year === this.form.year)
    //   }
    //   if (this.form.chapter) {
    //     data = data.filter((element) => element.chapter.id === this.form.chapter.id)
    //   }
    //   return data;
    // }
  },
  watch: {
  },
  created() {
    if (!isNaN(this.$route.params.id)) {
      this.getSingleElement()
    }
  },
  mounted() {
  },
  methods: {
    getSingleElement() {
      const link = `${this.$store.state.Data.Page.link}${this.$route.params.id}`
      getAPI.get(
        link,
        {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        },
      )
        .then((response) => {
          // console.log(response)
          this.populateForm(response.data)
        })
        .catch((err) => {
          this.$store.dispatch('setError', err);
        });
    },
    // getChart(number) {
    //   console.log(number)
    //   if (this.Charts) {
    //     return this.Charts.find((element) => element.number === number)
    //   }
    //   return null
    // },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        Object.keys(this.$v.form).forEach((key) => {
          if (!this.$v.form[key].$invalid) return;
          this.errors[key] = this.error_messages[key][(Object.keys(this.error_messages[key]).find((err) => (err !== 'invalid' && !this.$v.form[key][err])) || 'invalid')];
        });
        return;
      }

      this.$v.form.$reset();

      const fields = this.form;
      // console.log(fields)

      if (this.form.id) {
        this.$store.dispatch('UpdateItem', {
          // ref: 'Page', formdata: fields, postActions: [['getData', {ref: 'Page'}]],
          ref: 'Page', formdata: fields, postActions: [],
        });
      } else {
        this.$store.dispatch('CreateItem', {
          // ref: 'Page', formdata: fields, postActions: [['getData', {ref: 'Page'}]],
          ref: 'Page', formdata: fields, postActions: [],
        });
        this.$router.push('/pages')
      }
    },
    populateForm(data) {
      // console.log(data)
      this.form = data;
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },
  },
  validations() {
    return {
      form: {
        year: {
          required,
        },
        chapter: {
          required,
        },
        item_order: {
          required,
        },
        title: {
          required,
        },
        text: {
          required,
        },
      },
    };
  },
};
</script>

<style >
h1.section-header {
    font-family: 'Noto Sans', sans-serif;
    font-size: 26px;
    line-height: 38px;
    text-transform: uppercase;
    color: #005178;
    margin-top: 68px;
    margin-bottom: 48px;
}

h2.subsection-header {
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    line-height: 38px;
    color: #005178;
    margin-top: 48px;
    margin-bottom: 18px;
}

.header-block {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 24px;
    background: #ccc;
    padding: 1em;
    border-left: 10px solid #d4e4ef;
}

.header-block span {
    color: inherit;
    font-weight: bold;
}
  .tbl {
    width: 100%;
  }
  .tbl td {
    padding: 4px;
    vertical-align:middle;
  }
  .tbl tr.blue td {
    background-color: #005178;
    color: #fff;
  }
  .tbl tr.blue td p, tr.white td:not(:first-child) p, tr.gray td:not(:first-child) p {
    text-align: center;
}
.tbl tr.blue td:not(:last-child), .tbl tr.gray-bold td:not(:last-child) {
    border-right: 1px solid white;
}
.tbl tr.gray-bold td {
    background-color: #ededed;
    text-align: center;
    font-weight: bold;
}

.tbl tr.gray td {
    background-color: #ededed;
}
.tbl tr.gray-big td {
    background-color: #ededed;
    color: #005178;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
.tbl tr.gray td, .tbl tr.gray-big td, .tbl tr.gray-bold td, tr.white td {
    border-bottom-color: #c6c6c6;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}
.tbl tr.centered {
    text-align: center;
}
.tbl tbody tr:last-child td {
    border-bottom-color: #005178;
    border-bottom-style: solid;
    border-bottom-width: 2px;
}
.tbl tr.blue td, .tbl tr.gray td:nth-child(n+2), tr.white td:nth-child(n+2) {
    text-align: center;
}
.tbl tr.blue:nth-child(n+2) td {
    border-top: 1px solid white;
    border-right: 1px solid white;
}
.tbl td.highlight {
    background-color: #ededed;
}

  p.page-header {
    font-size: 36px;
    text-align: right;
}
p.simple-text {
    margin-bottom: 1em;
    text-align: justify;
}
p.footnote span {
    font-size: 12px;
    display: block;
    color: #4581a0;
}
p.footnote span {
    margin-bottom: 10px;
}
p.footnote span:last-child {
    margin-bottom: 48px;
}

.chart-block, .map-block, .ps-block {
    margin-bottom: 32px;
}

  .preview {
    border: 1px solid #714dd2;;
    padding: 5px;
    /* box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); */
    border-radius: 6px;
    height: 50vh;
    overflow: scroll;
  }
</style>
