<template>
  <!-- <div class="columns">
    <div class="column is-one-third"> -->
  <h3 class="title">
    {{ title }}
  </h3>
  <!-- </div>
  </div> -->
</template>

<script>

export default {
  name: 'PageTitle',
  components: {
  },
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
};
</script>

<style scoped>
  #coral {
    color: coral;
  }
</style>
