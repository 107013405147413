<template>
  <div
    :class="'map' + number"
  >
    <p
      :id="'r_' + number"
      class="header-block"
    >
      <b>Рис. {{ number }}</b> {{ title }}
    </p>
    <div class="map-block">
      <iframe :src="link" />
    </div>
    <p
      v-if="notes"
      class="footnote"
    >
      <span
        v-for="(note, index) in notes"
        :key="index"
      >
        {{ note }}
      </span>
    </p>
    <p class="footnote">
      <span>Источник: {{ source }}</span>
    </p>
  </div>
</template>

<script>

export default {
  name: 'MapPreview',
  components: {
  },
  props: {
    number: {
      type: String,
      default: () => {
        return ''
      }
    },
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    link: {
      type: String,
      default: () => {
        return ''
      }
    },
    notes: {
      type: Array,
      default: () => {
        return []
      }
    },
    source: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
.map-block iframe {
  width: 100%;
  min-height: 400px;
  padding: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
</style>
