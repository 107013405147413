<template>
  <div>
    <PageTitle title="Вход" />
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <!-- <b-message title="Внимание!" type="is-warning" aria-close-label="Close message">
            Произошло большое обновление системы, у некоторых пользователей могут неправильно
            работать или отображаться страницы.<br>
            При загрузке любого раздела рекомендуется обновить страницу для получения ее
            актуальной версии.<br>
            При возникновении проблем просьба сообщать о них в письме на
            <a href="karaman@vims-geo.ru" target="_blank">karaman@vims-geo.ru</a>
            или по телефону +79167308761
        </b-message> -->
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <div class="box">
              <h2 v-if="wrongCred">
                Введены неправильные учетные данные.
                {{ message }}
              </h2>
              <form @submit.prevent="loginUser">
                <b-field
                  label="Имя пользователя"
                >
                  <b-input
                    v-model="username"
                    name="username"
                    type="text"
                  />
                </b-field>
                <b-field
                  label="Пароль"
                >
                  <b-input
                    v-model="password"
                    name="password"
                    type="password"
                  />
                </b-field>
                <button
                  type="submit"
                  class="button"
                >
                  Войти
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PageTitle from '../components/PageTitle.vue';

export default {
  name: 'LoginPage',
  components: {
    PageTitle,
  },
  data() {
    return {
      username: '',
      password: '',
      wrongCred: false, // activates appropriate message if set to true
      message: '',
    };
  },
  methods: {
    loginUser() { // call loginUSer action
      this.$store.dispatch('loginUser', {
        username: this.username,
        password: this.password,
      })
        .then(() => {
          this.wrongCred = false;
          this.$router.push({ name: 'Home' });
        })
        .catch((err) => {
          // console.log(err);
          this.message = err;
          this.wrongCred = true; // if the credentials were wrong set wrongCred to true
        });
    },
  },
};
</script>

<style scoped>
</style>
